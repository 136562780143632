@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

.header {
  z-index: 9;
  position: relative;
  align-items: center;
  display: flex;
  padding: 0 0 1.5rem 2rem;

  a {
    text-decoration: none;
  }
}

.header__logo-image {
  height: 100px;
  transform: translateY(1rem);
}

.header__nav-container {
  flex: 70%;
  padding: 0 2rem;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 1179px) {
    padding-right: 0;
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .header {
    padding-left: 1rem;
  }

  .header__logo-image {
    height: 80px;
  }
}
