@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

// Reset button to sensible defaults
%button-base {
  background: none;
  outline: none;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  color: $color-primary;
  font-family: $font-accent;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

%button {
  @extend %button-base;

  letter-spacing: 1px;
  text-align: center;
  padding: 0.6rem 1.25rem;
}

.button {
  @extend %button;

  color: $color-button-primary;
  background-color: $color-button-background-primary;
  border-radius: 8px;
  text-transform: lowercase;
  font-size: 1.25rem;
  box-shadow: 0 2px 2px 0 $color-shadow-primary;
}

.button--secondary {
  @extend %button;

  color: $color-button-secondary;
  background-color: $color-button-background-secondary;
  border: 1px solid $color-border-tertiary;
  border-radius: 12px;
  font-family: $font-body;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 500;
}

.button--help {
  @extend %button-base;

  border-bottom: 1px dashed;
  font-size: 1rem;
  text-transform: lowercase;
}

.button--help-centered {
  @extend .button--help;

  display: block;
  margin: 0 auto;
}

@media only screen and (max-width: em($bp-sm)) {
  .button {
    font-size: 1.125rem;
  }

  .button--secondary {
    font-size: 1rem;
  }

  .button--help {
    font-size: 0.85rem;
  }
}
