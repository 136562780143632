@use "./variables" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

.landing-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $color-landing-background;

  .nav-header {
    padding: 1.5rem 0;
  }

  .nav-header__menu-container li a,
  .nav__hamburger,
  .footer a {
    color: $color-landing-text;

    &:hover {
      color: $color-landing-hover;
    }
  }

  .logo {
    color: $color-landing-text;
  }

  .section-wrapper {
    margin-bottom: 3rem;
  }
}

.landing {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: $font-body;
  margin: 0 2rem;
  color: $color-landing-text;
  transform: translateY(-1rem);
}

.landing__image img {
  height: 400px;
}

.landing__button {
  display: block;
  position: relative;
  outline: none;
  cursor: pointer;
  padding: 1rem 3rem;
  font-size: 1.4rem;
  letter-spacing: 2px;
  text-transform: lowercase;
  color: #0f4656;
  background-color: #9be0e0;
  border-radius: 20px;
  transition: all 0.2s ease;
  text-decoration: none;
  margin: 0;
}

.landing__text {
  text-align: justify;
  margin-top: 6rem;
  max-width: 52rem;

  p {
    font-size: 1.25rem;
    line-height: 2;
    letter-spacing: 1px;
    margin: 0;

    &:first-child {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: em($bp-sm)) {
  .landing__image img {
    height: 325px;
  }

  .landing__button {
    font-size: 1.2rem;
    padding: 0.75rem 2rem;
  }

  .landing__text {
    text-align: initial;
    margin-top: 3rem;

    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}
