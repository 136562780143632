@use "./variables" as *; /* load without namespace for convenience */
@use "./mixins" as *; /* load without namespace for convenience */
@use "./functions" as *; /* load without namespace for convenience */

.votingstatus-container {
  display: flex;
  justify-content: center;
  align-items: center;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    14px,
    16px
  ); /* min font-size 0.875rem, max 1rem */

  svg {
    flex-shrink: 0;
    margin-right: 1rem;
  }
}

.votingstatus {
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    13px,
    14.4px
  ); /* min font-size 0.8125rem, max 0.9rem */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.votingstatus__timer {
  font-family: $font-mono;
  @include fluid-type(
    $bp-sm,
    $bp-xl,
    13px,
    14.4px
  ); /* min font-size 0.8125rem, max 0.9rem */
}

// voting bar styles

$bar-height: 14px;

%vote-segment {
  display: block;
  position: absolute;
  height: $bar-height;
  transition: 0.4s linear;
}

.votingbar-container {
  position: relative;
  margin-top: 2rem;

  flex: 1 1 100%;
}

.votingbar {
  border-radius: 12px;
  height: $bar-height;
  overflow: hidden;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  background-color: $color-background-secondary;

  &__segment--yes {
    @extend %vote-segment;

    left: 0;
    background-color: $color-accent-secondary;
  }

  &__segment--no {
    @extend %vote-segment;

    right: 0;
    background-color: $color-accent-tertiary;
  }
}

.votingbar__percentages {
  font-size: 1rem;
  margin: 1rem 0 0.5rem;
  position: relative;
  color: $color-accent-primary;

  .yes-percent {
    position: relative;
    left: 22%;
  }

  .no-percent {
    position: absolute;
    right: 22%;
  }

  .yes-check {
    position: absolute;
    left: 10%;

    @media only screen and (max-width: em($bp-sm)) {
      left: 0;
    }
  }

  .no-check {
    position: absolute;
    right: 10%;

    @media only screen and (max-width: em($bp-sm)) {
      right: 0;
    }
  }
}

.votingbutton--voted {
  &::before {
    content: "\2713"; /* check mark */
    margin-left: -1.125em;
    color: $color-accent-primary;
    position: absolute;
  }
}
